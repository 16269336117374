import { loadView } from "@/router/loadView"
export default [
    //#region 【1】 (食品檢驗)
    {
        path: '/foods/:id/inspect',
        component: loadView("FoodProcessing/FoodInspectView/FoodInspectView"),
        name: "FoodInspectView",
        meta: { roles: ['admin'] }, // 需要為 Admin
        redirect: { name: 'SweetView' },
        children: [
            { 
                path: 'sweet', 
                name: 'SweetView', 
                meta: { roles: ['admin'] }, 
                component: loadView("FoodProcessing/FoodInspectView/Inspect/SweetView") 
            },
            { 
                path: 'color', 
                name: 'ColorView', 
                meta: { roles: ['admin'] }, 
                component: loadView("FoodProcessing/FoodInspectView/Inspect/ColorView") 
            },
            { 
                path: 'fullness', 
                name: 'FullnessView', 
                meta: { roles: ['admin'] }, 
                component: loadView("FoodProcessing/FoodInspectView/Inspect/FullnessView") 
            },
            { 
                path: 'ice', 
                name: 'IceView', 
                meta: { roles: ['admin'] }, 
                component: loadView("FoodProcessing/FoodInspectView/Inspect/IceView") 
            },
            { 
                path: 'total', 
                name: 'TotalView', 
                meta: { roles: ['admin'] }, 
                component: loadView("FoodProcessing/FoodInspectView/Inspect/TotalView") 
            },
        ],
    },
    //#endregion




    //#region 【2】 (上傳資料)
    {   // 品評圖
        path: '/food-upload/taste/image',
        name: 'UploadTasteImage',
        component: loadView("FoodProcessing/FoodUploadView/UploadImage/UploadTasteImage"),
        meta: { roles: ['admin'] }  // 需要為 Admin
    },
    {   // 蝦色圖
        path: '/food-upload/color/image',
        name: 'UploadColorImage',
        component: loadView("FoodProcessing/FoodUploadView/UploadImage/UploadColorImage"),
        meta: { roles: ['admin'] }  // 需要為 Admin
    },

    { // 上傳食品資料
        path: '/food-upload/excel',
        component: loadView("FoodProcessing/FoodUploadView/UploadFoodExcel"),
        name: "UploadFoodExcel",
        meta: { roles: ['admin'] }, // 需要為 Admin
        redirect: { name: 'UploadSweetExcel' },
        children: [
            { path: 'sweet', name: 'UploadSweetExcel', meta: { roles: ['admin'] }, component: loadView("FoodProcessing/FoodUploadView/UploadExcel/UploadSweetExcel") },
            { path: 'color', name: 'UploadColorExcel', meta: { roles: ['admin'] }, component: loadView("FoodProcessing/FoodUploadView/UploadExcel/UploadColorExcel") },
            { path: 'fullness', name: 'UploadFullnessExcel', meta: { roles: ['admin'] }, component: loadView("FoodProcessing/FoodUploadView/UploadExcel/UploadFullnessExcel") },
            { path: 'ice', name: 'UploadIceExcel', meta: { roles: ['admin'] }, component: loadView("FoodProcessing/FoodUploadView/UploadExcel/UploadIceExcel") },
        ],
    },
  
    //#endregion



    //#region 【2】 (食品管理)
    { // 全蝦
        path: '/food-management/for-whole',
        name: 'FoodManagementForWhole',
        component: loadView("FoodProcessing/FoodManagement/ManagementView/FoodManagementForWhole"),
        meta: { roles: ['admin'] }  // 需要為 Admin
    },

    { // 加工蝦 (:id - 加工蝦種類) => 5102: 蝦仁...
                path: '/food-management/for-process/:id',
                name: 'FoodManagementForProcess',
                component: loadView("FoodProcessing/FoodManagement/ManagementView/FoodManagementForProcess"),
                meta: { roles: ['admin'] },  // 需要為 Admin
                redirect: { name: 'SweetForProcessView' },
                children: [
                    { 
                        path: 'sweet', 
                        name: 'SweetForProcessView', 
                        meta: { roles: ['admin'] }, 
                        component: loadView("FoodProcessing/FoodManagement/ManagementView/ForProcess/SweetForProcessView") 
                    },
                    { 
                        path: 'color', 
                        name: 'ColorForProcessView', 
                        meta: { roles: ['admin'] }, 
                        component: loadView("FoodProcessing/FoodManagement/ManagementView/ForProcess/ColorForProcessView") 
                    },
                    { 
                        path: 'ice', 
                        name: 'IceForProcessView', 
                        meta: { roles: ['admin'] }, 
                        component: loadView("FoodProcessing/FoodManagement/ManagementView/ForProcess/IceForProcessView") 
                    },
                ]
            },
    
    //#endregion

    //#region 【2】 (感官品評)
    {
        path: '/food-taste',
        name: 'FoodTaste',
        component: loadView("FoodProcessing/Taste/FoodTaste"),
        meta: { roles: ['admin'] },  // 需要為 Admin
        redirect: { 
            name: 'FoodTasteView',
            params: {
                id: "5101"
            } 
        },
        children: [
            {
                path: 'view/:id',
                name: 'FoodTasteView',
                component: loadView("FoodProcessing/Taste/FoodTasteView"),
                props: true,
                meta: {
                    roles: ['admin']
                }, // 需要為 Admin或 Manager
            }
            
        ],
    },
    //#endregion

   

    //#region 【4】 (RCW 360溫度計)
    { /* RCW360 */
        path: '/rcw360-temp',
        name: 'RCW360_ThermometerList',
        component: loadView("FoodProcessing/RCW360/ThermometerList"),
        meta: { roles: ['admin', 'jutian'] } // 需要為 Admin
    },

    { /* RCW360(View) */
        path: '/rcw360-temp/:id',
        name: 'RCW360_ThermometerView',
        component: loadView("FoodProcessing/RCW360/ThermometerView"),
        meta: { roles: ['admin', 'jutian'] } // 需要為 Admin
    },
    //#endregion

    //#region 【5】 (生產紀錄)
    { /* 生產紀錄 */
        path: '/product',
        name: "Product",
        component: loadView("FoodProcessing/Product/ProductView"),
        meta: { roles: ['admin', 'marketing'] }, // 需要為 Admin
        redirect: { name: 'ProductYearAnalysis' },
        children: [
            { path: 'year-analysis', name: 'ProductYearAnalysis', meta: { roles: ['admin', 'marketing'] }, component: loadView("FoodProcessing/Product/ProductManagement/ProductYearAnalysis") },
            { path: 'packages', name: 'ProductPackageDetail', meta: { roles: ['admin', 'marketing'] }, component: loadView("FoodProcessing/Product/ProductManagement/ProductPackageDetail") },
            { path: 'position-detail', name: 'ProductPositionDetail', meta: { roles: ['admin', 'marketing'] }, component: loadView("FoodProcessing/Product/ProductManagement/ProductPositionDetail") },
            { path: 'position-process', name: 'ProductProcess', meta: { roles: ['admin', 'marketing'] }, component: loadView("FoodProcessing/Product/ProductManagement/ProductProcess") },
            { path: 'position-management-list', name: 'ProductManagementList', meta: { roles: ['admin', 'marketing'] }, component: loadView("FoodProcessing/Product/ProductManagement/ProductManagementList") },
        ],
    },
    //#endregion

    //#region 【6】 ()

    //#endregion

];