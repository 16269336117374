
import dayjs from 'dayjs'
const getDefaultState = () => { // state
    return {
        // 於 Sidebar中已經勾選的 product
        product_selected: [],

        range_date_idx: [
            dayjs().subtract(1, 'month').format("YYYY-MM-DD"), 
            dayjs().format("YYYY-MM-DD")
        ],

    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    
    mutations: { 
        setProductSelected(state, payload){
            state.product_selected = payload || []
        },

        setRangeDateIdx(state, payload){
            state.range_date_idx = payload
        },
      
    },
    
    actions: { 
       

   
    },

}