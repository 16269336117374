import { loadView } from "@/router/loadView"
export default [
    {
        path: '/maintenance-order-view',
        name: 'MaintenanceOrderView',
        component: loadView("MaintenanceManagement/MaintenanceOrderView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'], title: "維運管理"}, // 需要為 Admin
        children: [
            // is_entrance: 是否為入口
            //#region (待收訂單)
            { // 待收訂單
                path: 'pending', 
                name: 'PendingOrderView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "待收訂單", icon: "mdi-tools"}, 
                component: loadView("MaintenanceManagement/View/PendingOrderView"),
            },
            { // 待收訂單 / 指派維修人員
                path: 'pending/:id/assign', 
                name: 'AssignPendingOrder', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner'] , title: "待收訂單 / 指派維修人員", icon: "mdi-tools"}, 
                component: loadView("MaintenanceManagement/View/PendingOrderView/AssignPendingOrder"),
            },
            { // 待收訂單 / 接單確認
                path: 'pending/:id/receive', 
                name: 'ReceivePendingOrder', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_receiver'] , title: "待收訂單 / 接單確認", icon: "mdi-tools"}, 
                component: loadView("MaintenanceManagement/View/PendingOrderView/ReceivePendingOrder"),
            },
            //#endregion

            //#region (訂單進度)
            { // 訂單進度
                path: 'progress', 
                name: 'OrderProgressView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "訂單進度", icon: "mdi-calendar-clock-outline"}, 
                component: loadView("MaintenanceManagement/View/OrderProgressView"),
            },
            { // 待收訂單 / 回報進度
                path: 'progress/:id/report', 
                name: 'ReportProgressOrder', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "待收訂單 / 回報進度", icon: "mdi-calendar-clock-outline"}, 
                component: loadView("MaintenanceManagement/View/OrderProgressView/ReportProgressOrder"),
            },
            //#endregion


            { // 歷史訂單
                path: 'history', 
                name: 'HistoryOrderView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "歷史訂單", icon: "mdi-calendar-check-outline"}, 
                component: loadView("MaintenanceManagement/View/HistoryOrderView"),
            },

            { // 歷史訂單 / 訂單明細
                path: 'history/:id', 
                name: 'HistoryOrderItemView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "歷史訂單 / 訂單明細", icon: "mdi-calendar-check-outline"}, 
                component: loadView("MaintenanceManagement/View/HistoryOrderView/HistoryOrderItemView"),
            },
            
            { // 後續追蹤
                path: 'tracking', 
                name: 'FollowUpTrackingView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "後續追蹤", icon: "mdi-link"}, 
                component: loadView("MaintenanceManagement/View/FollowUpTrackingView"),
            },
            { // 人員管控
                path: 'personnel', 
                name: 'PersonnelManagementView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "人員管控", icon: "mdi-account-wrench"}, 
                component: loadView("MaintenanceManagement/View/PersonnelManagementView"),
            },
            { // 人員管控(詳細成員)
                path: 'personnel/detail/:id', 
                name: 'PersonnelDetailView', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'] , title: "人員管控", icon: "mdi-account-wrench"}, 
                component: loadView("MaintenanceManagement/View/PersonnelManagementView/PersonnelDetailView"),
            },
            { // 系統設定
                path: 'configuration', 
                name: 'ConfigurationView', 
                meta: { is_entrance: true, roles: ['tester', 'admin', 'manager', 'maintenance_assigner'] , title: "系統設定", icon: "mdi-cog"}, 
                component: loadView("MaintenanceManagement/View/ConfigurationView"),
            },
            { // 系統設定 / 進度狀態
                path: 'configuration/status', 
                name: 'ConfigureOrderStatus', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner'] , title: "系統設定 / 訂單進度狀態", icon: "mdi-cog"}, 
                component: loadView("MaintenanceManagement/View/ConfigurationView/ConfigureOrderStatus"),
            },
            { // 系統設定 / 優先序
                path: 'configuration/priority', 
                name: 'ConfigureOrderPriority', 
                meta: { is_entrance: false, roles: ['tester', 'admin', 'manager', 'maintenance_assigner'] , title: "系統設定 / 訂單優先序", icon: "mdi-cog"}, 
                component: loadView("MaintenanceManagement/View/ConfigurationView/ConfigureOrderPriority"),
            },

        ]

    },

    {
        path: '/maintenance-equipment-view',
        name: 'MaintenanceEquipmentView',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'], title: "設備清冊"}, // 需要為 Admin
    },

    {
        path: '/maintenance-asset-view',
        name: 'MaintenanceAssetView',
        component: loadView("MaintenanceManagement/MaintenanceAssetView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'], title: "固資財產"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/inventory/view',
        name: 'MaintenanceEquipmentInventoryView',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentInventoryView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "設備品項管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/inventory/view/create',
        name: 'MaintenanceEquipmentInventoryViewForCreate',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentInventory/MaintenanceEquipmentInventoryViewForCreate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "新增設備品項管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/inventory/view/update/:id',
        name: 'MaintenanceEquipmentInventoryViewForUpdate',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentInventory/MaintenanceEquipmentInventoryViewForUpdate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "修改設備品項管理"}, // 需要為 Admin
    },


    {
        path: '/maintenance-device-view',
        name: 'MaintenanceDeviceView',
        component: loadView("MaintenanceManagement/MaintenanceDeviceView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "案場設備管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance-device-view/create',
        name: 'MaintenanceDeviceViewForCreate',
        component: loadView("MaintenanceManagement/MaintenanceDeviceView/MaintenanceDeviceViewForCreate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "新增案場設備管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance-device-view/update/:id',
        name: 'MaintenanceDeviceViewForUpdate',
        component: loadView("MaintenanceManagement/MaintenanceDeviceView/MaintenanceDeviceViewForUpdate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "修改案場設備管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/position/inventory-allocate/view',
        name: 'MaintenanceEquipmentPositionInventoryAllocateView',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentPositionInventoryAllocateView"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "案場設備管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/position/inventory-allocate/view/create',
        name: 'MaintenanceEquipmentPositionInventoryAllocateViewForCreate',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentPositionInventoryAllocateView/MaintenanceEquipmentPositionInventoryAllocateViewForCreate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "新增案場設備管理"}, // 需要為 Admin
    },

    {
        path: '/maintenance/equipment/position/inventory-allocate/view/update/:id',
        name: 'MaintenanceEquipmentPositionInventoryAllocateViewForUpdate',
        component: loadView("MaintenanceManagement/MaintenanceEquipmentPositionInventoryAllocateView/MaintenanceEquipmentPositionInventoryAllocateViewForUpdate"),
        meta: { roles: ['tester', 'admin', 'manager', 'maintenance_assigner', 'team_leader'], title: "修改案場設備管理"}, // 需要為 Admin
    },

];