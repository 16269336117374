// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class AlertEmptyInputService extends BaseService{
    constructor(){
        super('alert-empty-input') // 帶入 order為 resource
    }

    get_positions(params) {
        return service.get(`/${this.resource}/positions/`, { params });
    }

    get_position_by_id(id, params) {
        return service.get(`/${this.resource}/positions/${id}/`, { params });
    }

}


// 將封裝的 class匯出
export default new AlertEmptyInputService()