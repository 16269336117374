// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class MaintenanceStatusService extends BaseService{
    constructor(){
        super('breeding/records/maintenance-record/configure/status') // 帶入 category為 resource
    }
}


// 將封裝的 class匯出
export default new MaintenanceStatusService()