// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class MaintenanceAssetService extends BaseService{
    constructor(){
        super('breeding/records/maintenance-record/asset') // 帶入 category為 resource
    }

    get_invoice_date(params){
        return service.get(`${this.resource}/invoice-dates/`, {params})
    }
    get_manufacturer(params){
        return service.get(`${this.resource}/manufacturers/`, {params})
    }
}


// 將封裝的 class匯出
export default new MaintenanceAssetService()