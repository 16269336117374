import { MaintenanceStatusService, MaintenancePriorityService } from "@/api/services"

const getDefaultState = () => { // state
    return {
        // 進度狀態的樣式
        status_chip_style: {},

        // 優先序的樣式
        priority_chip_style: {},
        
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        // 查看是否有 status_chip_style
        isIncludeStatusChipStyle: (state) => {
            return Object.keys(state.status_chip_style).length > 0
        },
        // 查看是否有 priority_chip_style
        isIncludePriorityChipStyle: (state) => {
            return Object.keys(state.priority_chip_style).length > 0
        }
    },

    mutations: { 
        setStatusChipStyle(state, payload){
            state.status_chip_style = payload
        },
        setPriorityChipStyle(state, payload){
            state.priority_chip_style = payload
        }
    },
    
    actions: { 
        //#region (取得維運進度狀態的樣式)
        async fetchStatusChipStyle({ commit }){
            try {
                const params = new URLSearchParams({
                    no_page: true, // 不要分頁
                })
                const response = await MaintenanceStatusService.getAll(params)
                if (response.status === 200){
                    const map_data = {}
                    for(const data of response.data){
                        const { id } = data
                        map_data[id] = {
                            name: data['name'],
                            color: data['color'],
                            icon: data['icon'],
                            icon_color: data['icon_color']
                        }
                    }
                    
                    commit("setStatusChipStyle", map_data)
                   
                }
            }catch(err){
                console.log(`store => fetchStatusChipStyle: ${err}`);
            }
        },
        //#endregion
        
        
        //#region (取得維運優先序的樣式)
        async fetchPriorityChipStyle({ commit }){
            try {
                const params = new URLSearchParams({
                    no_page: true, // 不要分頁
                })
                const response = await MaintenancePriorityService.getAll(params)
                if (response.status === 200){
                    const map_data = {}
                    for(const data of response.data){
                        const { id } = data
                        map_data[id] = {
                            name: data['name'],
                            color: data['color'],
                            icon: data['icon'],
                            icon_color: data['icon_color']
                        }
                    }
                    
                    commit("setPriorityChipStyle", map_data)
                   
                }
            }catch(err){
                console.log(`store => fetchPriorityChipStyle: ${err}`);
            }
        }
        //#endregion
    },

}