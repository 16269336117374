import service from '@/api/service'
  
const url = '/product-management'
  
export const getProductManagementList = (params) => service.get(`${url}/`, {params})
export const getProductManagementDetail = (id, params) => service.get(`${url}/${id}/`, {params})
export const getProductDates = (params) => service.get(`${url}/dates/`, {params})
export const getProductYearAnalysis = (params) => service.get(`${url}/year-analysis/`, {params})
export const getProductYearReport = (params) => service.get(`${url}/year/report/`, {params})
export const getProductPackageManagement = (params) => service.get(`${url}/packages/`, {params})
export const getProductPositionManagement = (params, position_id) => service.get(`${url}/packages/${position_id}/`, {params})