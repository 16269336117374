import { loadView } from "@/router/loadView"

export default [

    //#region (養殖記錄 - 用戶輸入)
    {  // 叫料
        path: '/feed-record-mobile/view/:id/:item',
        name: 'MaterialOrderRecord',
        component: loadView("BreedingRecord/Material/MaterialOrderRecord"),
        meta: {
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },

    {  // 維運
        path: '/feed-record-mobile/view/maintenance/:id/:item',
        name: 'MaintenanceRecord',
        component: loadView("BreedingRecord/Maintenance/MaintenanceRecord"),
        meta: {
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },

    //#endregion


    // // 歷史紀錄 (每日記事列表)
    {  
        path: '/daily-history',
        name: 'DailyHistoryView',
        component: loadView("BreedingRecord/DailyHistory/DailyHistoryView"),
        meta: {
            is_menu: true, title: '歷史紀錄', icon: "mdi-clipboard-text-clock",
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },
    // // 叫料訂單
    {  
        path: '/material/orders',
        name: 'MaterialOrderView',
        component: loadView("BreedingRecord/Material/MaterialOrderView"),
        meta: { 
            is_menu: true, title: '叫料訂單', 
            roles: ['admin', 'shipper'], icon: 'mdi-palette-swatch-variant', 
        },
        redirect: { name: 'PendingView' },
        children: [
            { 
                path: 'pending', 
                name: 'PendingView', 
                meta: { roles: ['admin', 'shipper'] }, 
                component: loadView("BreedingRecord/Material/MaterialOrderView/PendingView"),
                redirect: { name: 'PendingOrderSelectView' },
                children: [
                    { 
                        path: 'select', 
                        name: 'PendingOrderSelectView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Pending/PendingOrderSelectView") 
                    },
                    { 
                        path: 'calculate', 
                        name: 'PendingOrderCalculateView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Pending/PendingOrderCalculateView") 
                    },
                    { 
                        path: 'final', 
                        name: 'PendingOrderFinalView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Pending/PendingOrderFinalView") 
                    },
                ]
            },
            { 
                path: 'shipping', 
                name: 'ShippingView', 
                meta: { roles: ['admin', 'shipper'] }, 
                component: loadView("BreedingRecord/Material/MaterialOrderView/ShippingView"),
                redirect: { name: 'ShippingOrderListView' },
                children: [
                    { 
                        path: 'list', 
                        name: 'ShippingOrderListView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Shipping/ShippingOrderListView") 
                    },
                    { 
                        path: 'detail/:id', 
                        name: 'ShippingOrderDetailView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Shipping/ShippingOrderDetailView") 
                    },
                    { 
                        path: 'position/:id', 
                        name: 'ShippingOrderPositionView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Shipping/ShippingOrderPositionView") 
                    },
                ]
            },
            { 
                path: 'delivered', 
                name: 'DeliveredView', 
                meta: { roles: ['admin', 'shipper'] }, 
                component: loadView("BreedingRecord/Material/MaterialOrderView/DeliveredView"),
                redirect: { name: 'DeliveredOrderListView' },
                children: [
                    { 
                        path: 'list', 
                        name: 'DeliveredOrderListView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Delivered/DeliveredOrderListView") 
                    },
                    {
                        path: 'detail/:id', 
                        name: 'DeliveredOrderDetailView', 
                        meta: { roles: ['admin', 'shipper'] }, 
                        component: loadView("BreedingRecord/Material/MaterialOrderView/Delivered/DeliveredOrderDetailView") 
                    }
                   
                ]
            },
    
        ]

    },
    // // 維運日誌
    {  
        path: '/maintenance-history',
        name: 'MaintenanceHistoryView',
        component: loadView("BreedingRecord/Maintenance/MaintenanceHistoryView"),
        meta: { 
            is_menu: true, title: '維運日誌', 
            roles: ['admin', 'manager', 'team_leader', 'proxy', 'editor'], icon: 'mdi-tools', 
            keepAlive: true
        },
    },
    // // 叫料日誌
    {  
        path: '/material-history',
        name: 'MaterialHistoryView',
        component: loadView("BreedingRecord/Material/MaterialHistoryView"),
        meta: { 
            is_menu: true, title: '叫料日誌', 
            roles: ['admin', 'manager', 'team_leader', 'proxy', 'editor'], icon: 'mdi-palette-swatch-variant', 
            keepAlive: true
        },
    },
    // // 放流紀錄 (放流列表)
    {  
        path: '/destroy-history',
        name: 'DestroyHistoryView',
        component: loadView("BreedingRecord/DestroyHistory/DestroyHistoryView"),
        meta: {
            is_menu: true, title: '放流紀錄', icon: "mdi-clipboard-text-clock",
            roles: ['admin', 'manager', 'team_leader', 'proxy']
        }
    },
    // // 水質紀錄 
    {  
        path: '/water-history',
        name: 'WaterHistoryView',
        component: loadView("BreedingRecord/WaterHistory/WaterHistoryView"),
        meta: {
            is_menu: true, title: '水質紀錄', icon: "mdi-water",
            roles: ['admin', 'manager', 'team_leader', 'accountant', 'proxy']
        }
    },

    //#region (新版本) => Mobile(餵食量+水質+測重已經變成上面那個)
    {   /* 養殖紀錄(首頁總場) */
        path: '/feed-record-mobile',
        name: 'FeedRecordMobile',
        component: loadView("BreedingRecord/FeedRecord/FeedRecord"),
        meta: {
            is_menu: true, title: '養殖紀錄', icon: "mdi-book-plus",
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },

    {   /* 養殖紀錄(各場) */
        path: '/feed-record-mobile/view/:id',
        name: 'FeedRecordMobileView',
        component: loadView("BreedingRecord/FeedRecord/FeedRecordView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy'] }
    },

    // 養殖記錄
    {   /* 養殖紀錄(首頁總場) */
        path: '/feed-record-mobile/input-record/view/:id/:item',
        name: 'FeedRecordInput',
        component: loadView("BreedingRecord/FeedRecord/FeedRecordInput"),
        meta: {
            is_menu: true, title: '養殖紀錄', icon: "mdi-book-plus",
            roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy']
        }
    },
    //#endregion


];